@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');


:root {
    --font1: 1rem;
    --font2: 1.5rem; 
    --font3: 2.5rem;
    --font4: 2rem;
  
    --family1: "Archivo Black", sans-serif;
    --family2: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    --background: #f2f2f2;
    --color: black;

    --nav_hover_background: black;
    --nav_hover_color: white;
}


[data-theme="dark"]{
    --background: #24272d;
    --color: rgb(255, 255, 255);

    --nav_hover_background: white;
    --nav_hover_color: black;
}

*,
*::before,
*::after{
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    font-family: var(--family2);
    transition: background-color 0.3s;
    scroll-behavior: smooth;
}

html,
body {
    font-size: var(--font1);
    color: var(--color);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

a{
    text-decoration: none;
    cursor: pointer;
}

ul,
ol,
li{
    list-style: none;
}

img{
    vertical-align: top;
}

h1, 
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}