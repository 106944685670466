.App{
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
}
