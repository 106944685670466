.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30vh;
  margin-bottom: 30vh;
}

.formbox {
  margin: 5%;
}

form {
  width: 60%;
  padding: 3%;
}

form h3 {
  font-size: var(--font3);
  font-family: var(--family1);
}

label {
  font-size: var(--font1);
  font-family: "Courier New", Courier, monospace;
}

input,
textarea {
  background-color: transparent;
  border: 1px solid var(--color);
  border-radius: 10px;
  width: 100%;
  color: var(--color);
  font-family: "Courier New", Courier, monospace;
}

input {
  padding: 1%;
}

textarea {
  padding: 1%;
  height: 250px;
}

.contact button {
  border-radius: 10px;
  background-color: var(--color);
  color: var(--background);
  padding: 0px 30px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
