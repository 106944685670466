@keyframes jumpingEffect {
  0% {
    transform: translateX(1500px);
    opacity: 0;
  }
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.nav {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5vh 5vh 0 5vh;
}

.nav-left {
  padding: 0;
}

.nav-right {
  display: flex;
}

.nav-left img {
  width: 60px;
  height: 70px;
  margin: 0;
  border-radius: 50%;
}

button {
  background-color: transparent;
  color: var(--color);
  font-size: var(--font3);
  cursor: pointer;
}

.navBurger {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color);
  background-color: var(--background);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  animation: jumpingEffect 0.3s ease;
  z-index: 1;
}

.navBurger a {
  margin: 30px auto;
  color: var(--color);
}

.navBurger button {
  color: var(--color);
  position: absolute;
  right: 30px;
  top: 50px;
}

.link {
  font-size: 10px;
}

.navBurger .switch {
  margin: 40px auto;
}
